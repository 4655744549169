
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";

::selection {
  background: $brand-primary;
  color: #fff;
}
.grecaptcha-badge {
  visibility: hidden;
}
body {
  //padding-top: 70px;

  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;

  .full-height-section {
    min-height: calc(100vh - 130px);
  }

}

#banner {

  position: relative;
  height: 100vh;

  h1 {
    position: absolute;
    top: calc(50% - 38vh + 60px);
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    font-size: 15vh;
    font-weight: 200;
  }
}

h1 {
  color: #2a2a2a;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

h4 {
  color: #2a2a2a;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 15px;
}

// Navbar
.navbar {
  //min-height: 80px;
  background-color: rgba(0, 0, 0, 0.8);
}
.navbar-default {
  border: 0;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);

  .navbar-header {
    border-top: 3px solid transparent;

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;

      img {
        max-height: 100%;
      }
    }
  }
  .navbar-nav {

    padding-left: 30px;

    li {

      margin: 0 10px;

      a {
        font-size: 14px;
        font-family: Poppins, sans-serif;
        font-weight: 600;
        text-transform: uppercase;

        border-top: 3px solid transparent;

        color: #FFFFFF;

        &:hover {
          border-color: $brand-primary;
          color: $brand-primary;
        }

        &:focus {
          color: $brand-primary;
        }
      }

      &.active {
        a {
          border-color: $brand-primary;
          color: $brand-primary;

          &:hover {
            color: $brand-primary;
          }

          &:focus {
            color: $brand-primary;
          }
        }
      }
    }

    &.navbar-right {

      li {
        margin: 0;

        a {
          &:hover {
            border-color: transparent;
            color: $brand-primary;
            text-decoration: underline;
          }

          &:focus {
            color: $brand-primary;
            text-decoration: underline;
          }
        }

        &.active {
          a {
            border-color: transparent;
            color: $brand-primary;
            text-decoration: underline;


            &:hover {
              border-color: transparent;
              color: $brand-primary;
            }

            &:focus {
              color: $brand-primary;
            }
          }
        }
      }

    }
  }
}

.img-diamond {
  overflow: hidden;
  display: inline-block;
  max-height: 416px;
  max-width: 360px;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.block {

  .block-header {
    text-align: center;

    .img-diamond {
      width: 100%;
      min-height: 416px;
      background-size: cover;
    }

      img {
      display: inline-block;
      //max-height: 182px;
    }
  }

  .block-content {
    text-align: center;
  }
}

.client-holder {
  border: 1px solid #d6d6d6;
  padding: 5px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.1);
    border: 1px dashed #d6d6d6;
    background-color: #fff;
  }
}

#pre-footer {
  color: #ffffff;
  padding: 15px;
  min-height: 20vh;
  background-image: url('/images/footer-pattern.png');
  background-color: rgba(0,0,0,0.9);

  h1, h2, h3 {
    color: #ffffff;
    text-transform: uppercase;
  }

  .address-info {
    margin-bottom: 15px;
    padding-left: 15px;

    .font-bold {
      font-weight: bold;
    }

    a {
      color: #ffffff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .follow-us {

    text-align: right;

    .social {
      font-size: 200%;

      a {
        margin-right: 15px;
      }
    }
  }

  #contact-us-form {
    input, textarea {
      background-color: rgba(59, 59, 59, .35);
      margin: 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      text-transform: none;
      -webkit-appearance: none;
      border: 1px solid rgba(255,255,255,0.35);//rgba(69, 69, 69, .8);
      color: #ffffff; //#979797;

      &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    #ffffff;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #ffffff;
        opacity:  1;
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #ffffff;
        opacity:  1;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color:    #ffffff;
      }
      &::-ms-input-placeholder { /* Microsoft Edge */
        color:    #ffffff;
      }
    }

    button {
      width: 100%;
    }
  }

}

footer {
  height: 60px;
  background-color: rgba(56, 56, 56, 1);
  color: #fff;
  line-height: 60px;
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: #ffffff;
}

.text-brand {
  color: $brand-primary;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

  .block {

    .block-header {
      text-align: center;

      .img-diamond {
        width: 100%;
        min-height: 350px;
        background-size: cover;
      }
    }
  }

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .block {

    .block-header {
      text-align: center;

      .img-diamond {
        width: 100%;
        min-height: 270px;
        background-size: cover;
      }
    }
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

  #banner {
    height: 100%;

    h1 {
      top: 60px;
      font-size: 7vh;
    }
  }

  .block {
    text-align: center;

    .block-header {
      width: 100%;
      display: inline-block;
      text-align: center;
      max-width: 255px;

      .img-diamond {
        width: 100%;
        max-width: 255px;
        min-height: 293px;
        background-size: cover;
      }
    }
  }

  .img-diamond {
    width: 100%;
    max-width: 255px;
    height: 293px;
    background-size: cover;
  }

  #pre-footer .follow-us {
    text-align: left;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

  .block {

    .block-header {
      text-align: center;

      .img-diamond {
        width: 100%;
        min-height: 270px;
        background-size: cover;
      }
    }
  }

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

  .block {

    .block-header {
      text-align: center;

      .img-diamond {
        width: 100%;
        min-height: 270px;
        background-size: cover;
      }
    }
  }

}